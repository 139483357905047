<template>
  <b-card
    class="col-12"
    title="Projects">

    <b-button variant="success" v-b-modal.modal-project class="my-4">Add project</b-button>
    <br>
    <input type="search" placeholder="Search Projects..." @keyup="filterProjects" v-model="searchValue">   
    <br>
    <br>
    <b-table :items="filteredProjects" :fields="fields">
      <template #cell(open)="data">
        <div :class="'status-icon ' + (data.item.open ? 'green' : 'red')"  @click="toggleStatus(data.item)">
          <b-icon icon="check2" v-if="data.item.open"></b-icon>
          <b-icon icon="x" v-if="!data.item.open"></b-icon>
        </div>
      </template>
      <template #cell(delete)="data">
        <div class="row">
          <div class="col">
            <span class="status-icon" @click="loadUpdateProject(data.item)" v-b-modal.modal-update>
            <b-icon icon="pencil"></b-icon>
          </span>
          </div>
          <div class="col">
            <router-link :to="'questionnaire/' + data.item.id">
            <b-icon icon="card-list"></b-icon>
          </router-link>
          </div>
          <div class="col">
            <span class="status-icon" @click="deleteProject(data.item)">
            <b-icon icon="trash"></b-icon>
          </span>
          </div>
        </div>
      </template>
    </b-table>

    <b-modal id="modal-project" title="Add new project" @ok="saveProject">
      <p class="my-4">Give your project a name!</p>
      <b-form-input v-model="projectModel.name" placeholder="Project name" class="mb-3"></b-form-input>
      <p class="my-4">You can copy another project questionnaire if you want</p>
      <b-form-select v-model="projectModel.project" :options="selectProjects"></b-form-select>

      <p class="my-4">Add company names, divided by comma<br>Example: Google,Microsoft,Apple</p>
      <b-form-input v-model="projectModel.companies" placeholder="Companies" class="mb-3"></b-form-input>
    </b-modal>

    <b-modal id="modal-update" title="Update project" @ok="updateProject">
      <p class="my-4">Give your project a name!</p>
      <b-form-input v-model="updateModel.name" placeholder="Project name" class="mb-3"></b-form-input>
      <p class="my-4">Add company names, divided by comma<br>Example: Google,Microsoft,Apple</p>
      <b-form-input v-model="updateModel.companies" placeholder="Companies" class="mb-3"></b-form-input>
    </b-modal>
  </b-card>
</template>

<script>
export default {
  data: function () {
    return {
      projectModel: {
        name: '',
        project: '',
        companies: ''
      },
      updateModel: {
        id: '',
        name: '',
        companies: ''
      },
      projects: [],
      filteredProjects: [],
      selectProjects: [],
      fields: [
        {
          key: 'name',
          label: 'Project name',
          sortable: true
        },
        {
          key: 'companies',
          label: 'Companies',
          sortable: true
        },
        {
          key: 'createdDate',
          label: 'Creation date',
          sortable: true
        },
        {
          key: 'open',
          label: 'Status',
          sortable: true
        },
        {
          key: 'numUsers',
          label: 'Users',
          sortable: true
        },
        {
          key: 'numDone',
          label: 'Completed questionnaires',
          sortable: true
        },
        {
          key: 'delete',
          label: 'Delete'
        },
      ],
      searchValue: ""
    }
  },

  mounted () {
    this.loadProjects()
  },

  methods: {
    filterProjects() {
      this.filteredProjects = this.projects.filter((item) => {
        return item.name.toLowerCase().indexOf(this.searchValue.toLowerCase()) !== -1
      })
    },

    async loadProjects () {
      try {
        let resp = await this.$firestore.collection('projects').get()
        let parsed = this.getSnapshotRecords(resp, false)
   
        this.projects = parsed
        this.filteredProjects = parsed

        this.selectProjects = parsed.map((item) => {
          return {
            value: item.id,
            text: item.name
          }
        })
      } catch (e) {
        console.log(e)
      }
    },

    async toggleStatus (data) {
      try {
        await this.$firestore.collection('projects')
          .doc(data.id)
          .set({
            open: !data.open
          }, {merge: true})

        this.loadProjects()
      } catch (e) {
        console.log(e)
      }
    },

    async deleteProject (data) {
      try {
        await this.$firestore.collection('projects')
          .doc(data.id)
          .delete()

        this.loadProjects()
      } catch (e) {
        console.log(e)
      }
    },

    loadUpdateProject (item) {
      this.updateModel.name = item.name
      this.updateModel.companies = item.companies
      this.updateModel.id = item.id
    },

    async updateProject () {
      try {
        await this.$firestore.collection('projects')
          .doc(this.updateModel.id)
          .update({
            name: this.updateModel.name,
            companies: this.updateModel.companies,
          })

        location.reload()
      } catch (e) {
        console.log(e)
      }
    },

    async saveProject () {
      try {
        const newProject = await this.$firestore.collection('projects')
          .add({
            name: this.projectModel.name,
            companies: this.projectModel.companies,
            open: true,
            numUsers: 0,
            numDone: 0,
            createdDate: this.$firebase.firestore.Timestamp.now()
          })

        if (this.projectModel.project) {
          const questionnaire = await this.$firestore.collection('questionnaires')
            .where('project', '==', this.projectModel.project)
            .get()
          const parsed = this.getSnapshotRecords(questionnaire, true)

          await this.$firestore.collection('questionnaires')
            .add({
              project: newProject.id,
              general: parsed.general,
              sections: parsed.sections
            })
        }

        this.loadProjects()
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>
